console.log('%c created by. %cFINEAPPLE %cPTL ','color:#8cc677; font-size:14px; background-color:#555; font-weight:600; padding:4px 0;','color:#cbdd54; font-size:14px; background-color:#555; font-weight:600; padding:4px 0;','color:#57b69a; font-size:14px; background-color:#555; font-weight:600; padding:4px 0;');

gsap.registerPlugin(ScrollTrigger);

var clickEvent = (function () {
	if ('ontouchstart' in document.documentElement === true) {
		return 'touchstart';
	} else {
		return 'click';
	}
})();
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

//쿠키삭제
function deleteCookie(name) { 
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'; 
}

var slide01Player,
    slide02Player,
    mainPlayer2;

let testBool = true;
function toggleOpen(){testBool = testBool ? false : true;}


const header = document.getElementById('header');
const hamberger = document.querySelector('.hamberger');
const netCall = document.querySelector('.m-network-call-icon');
const gnb = document.getElementById('gnb');
const gnbD1nner = Array.from(document.getElementsByClassName('gnb-menu-depth1-inner'));
// 서브페이지 메뉴 스크립트 추가 210705
const tnb = document.getElementById('topNavigation');
const tnbDim = document.getElementById('subDim');
const tnbD1 = document.querySelectorAll('.sub-menu-depth1');

// 서브페이지 메뉴 스크립트 추가 210705
if(tnb){
  tnb.addEventListener('mouseenter',function(){
    header.classList.add('tnb-open');
    tnbDim.classList.add('tnb-open');
  });
  tnb.addEventListener('mouseleave',function(){
    header.classList.remove('tnb-open');
    tnbDim.classList.remove('tnb-open');
  });
  tnbDim.addEventListener('mouseenter',function(){
    header.classList.add('tnb-open');
    tnbDim.classList.add('tnb-open');
  });
  tnbDim.addEventListener('mouseleave',function(){
    header.classList.remove('tnb-open');
    tnbDim.classList.remove('tnb-open');
  });
}



var gnbTl = gsap.timeline({paused:true});
gnbTl.to('.gnb-left-bg',{duration:.8,ease:Power2.easeOut,autoAlpha:1,yPercent:100},0);
gnbTl.to('.gnb-right-bg',{duration:.8,ease:Power2.easeOut,autoAlpha:1,yPercent:100},0.05);
gnbD1nner.forEach((gnbD1,index)=>{
  gnbTl.fromTo(gnbD1,{
    y:10,
    autoAlpha:0,
  },{
    y:0,
    autoAlpha:1,
    delay:0.15*index
  },0.4);
});
gnbTl.fromTo('.gnb-event-wrap',{autoAlpha:0,},{autoAlpha:1,},.5);

const menuEvent = function(){
	document.body.classList.toggle('open');
	this.classList.toggle('open');
	gnb.classList.toggle('open');
  if(testBool){
    gnbTl.play();
  } else{
    gnbTl.reverse(0.3);
  }
  toggleOpen();
}
//클릭 이벤트
hamberger.addEventListener(clickEvent,menuEvent);

var burgerBtn = hamberger.querySelector('.burger');
var poinTtimer;

function initTimer(){
  poinTtimer = setInterval(timerHandler,2500);
}

function timerHandler(){
  burgerBtn.classList.toggle('open');
  // currentNum++;
  // if(currentNum == PointsLength){
  //   currentNum = 0;
  // }
}

initTimer();
  
hamberger.addEventListener('mouseenter',function(){
  clearInterval(poinTtimer);
  burgerBtn.classList.add('open');
  //currentNum = idx;
  //showPoint(idx);
  //changeClass(idx);
});
hamberger.addEventListener('mouseleave',function(){
  initTimer();
  burgerBtn.classList.remove('open');
});




var mainPlayer2 = null;
const equipSect = document.getElementsByClassName('equipment-sect')[0];
const equipList = document.querySelectorAll('.equipment__list li');
const equipMenu = Array.from(document.getElementsByClassName('equipment-menu-item'));

// var laserHoverTl = gsap.timeline();
// equipMenu.forEach(equips=>laserHoverTl.to(equips,{autoAlpha:0,scale:1.05,duration:.6},0));

if(equipSect){
  
  equipList.forEach((equip,index)=>{
    equip.addEventListener('mouseenter',function(){
      equipList.forEach(equips=>equips.classList.remove('hover'));
      equipList[index].classList.add('hover');

      equipMenu.forEach(equips=>equips.classList.remove('hover'));
      equipMenu[index].classList.add('hover');

      //equipMenu.forEach(el=>gsap.to(el,{autoAlpha:0,scale:1.05}));
      // laserHoverTl.play(0);
      // gsap.fromTo(equipMenu[index],{autoAlpha:0,scale:1.05},{duration:1,autoAlpha:1,scale:1});
      
      // mainPlayer2.setCurrentTime(6);
      // mainPlayer2.play();
    });
  });
  //equipMenu.forEach(equips=>gsap.set(equips,{autoAlpha:0,scale:1.05}));
  
}

const openBtns = Array.from(document.getElementsByClassName('open-on-hover'));
openBtns.forEach((button)=>{
  button.addEventListener('mouseenter',(e)=>{
    const list = button.getElementsByClassName('open-on-hover-list')[0];
    gsap.fromTo(list,{y:-20,autoAlpha:0},{y:0,autoAlpha:1});
  });
  button.addEventListener('mouseleave',(e)=>{
    const list = button.getElementsByClassName('open-on-hover-list')[0];
    gsap.fromTo(list,{y:0,autoAlpha:1},{y:-20,autoAlpha:0});
  });
});





const searchOpen = document.getElementsByClassName('header-search-icon')[0];
const headerSearch = document.getElementsByClassName('header-search-container')[0];
const searchClose = document.getElementsByClassName('header-search-close')[0];
const searchClose2 = document.getElementsByClassName('smooth-scroll')[0];

searchOpen.addEventListener(clickEvent,()=>{
  document.body.classList.add('search-mode');
  headerSearch.classList.add('search-open');
});
searchClose.addEventListener(clickEvent,()=>{
  document.body.classList.remove('search-mode');
  headerSearch.classList.remove('search-open');
});
searchClose2.addEventListener(clickEvent,()=>{
  document.body.classList.remove('search-mode');
  headerSearch.classList.remove('search-open');
})



const mainPage = document.querySelector('.main-visual-sect');
let locoScroll;
let cursor = function(e){
  circleCursor.style.top = e.pageY + "px";
  circleCursor.style.left = e.pageX + "px";
}
if(!mainPage){
  locoScroll = new LocomotiveScroll({
    el: document.querySelector(".smooth-scroll"),
    smooth: false
  })
  cursor = function(e){
    circleCursor.style.top = e.clientY + "px";
    circleCursor.style.left = e.clientX + "px";
  }
  
} else{
  locoScroll = new LocomotiveScroll({
    el: document.querySelector(".smooth-scroll"),
    smooth: true
  });
  
}


// each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
locoScroll.on("scroll", ScrollTrigger.update);

ScrollTrigger.scrollerProxy(".smooth-scroll", {
  scrollTop(value) {
    return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
  }, // we don't have to define a scrollLeft because we're only scrolling vertically.
  getBoundingClientRect() {
    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
  },
  // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
  pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
});

ScrollTrigger.matchMedia({
	
  // desktop
  "(min-width: 801px)": function() {
    // setup animations and ScrollTriggers for screens 800px wide or greater (desktop) here...
    // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
    
    var mainVisual
    document.addEventListener("DOMContentLoaded", function() {
      mainVisual = new Swiper('.main-visual', {
        speed:800,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop:true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
      });
      //mainVisual.on('slideChange',function(){
      //  var idx = mainVisual.realIndex;
      //  if(idx == 1){
      //    slide01Player.play();
      //    slide02Player.pause();
      //  }
      //  if(idx == 2){
      //    slide02Player.play();
      //    slide01Player.pause();
      //  }
      //});
    });
    /*
    const circleCursor = document.getElementById('circleCursor');
    const expandCursor = Array.from(document.getElementsByClassName('bind-cursor'));
    const slideCursor = document.querySelectorAll('.swiper-wrapper:not(.event-btn-wrapper)');
    const bigCursor = Array.from(document.getElementsByClassName('big-cursor'));

    var expandClass1 = 'is-expand',
        expandClass2 = 'is-expand-slider',
        expandClass3 = 'is-expand-big';


    document.addEventListener('mousemove',cursor);

    var expandSmall = gsap.timeline({paused:true});
    expandSmall.fromTo(circleCursor, {
      autoAlpha:0,
      backgroundColor: 'rgba(201,176,130,0)',
      scale: 1
    }, {
      duration:0.3,
      autoAlpha:1,
      backgroundColor: 'rgba(164, 164, 164, 1)',
      scale: 1.3,
      ease:Power4
    });

    var expandBig = gsap.timeline({paused:true});
    expandBig.fromTo(circleCursor, {
      autoAlpha:0,
      scale: 1
    }, {
      autoAlpha:1,
      scale: 1.5,
      ease:Power4
    });

    function expanding() {
      circleCursor.classList.add(expandClass1);
      expandSmall.play();
    }

    function expanded() {
      circleCursor.classList.remove(expandClass1);
      expandSmall.reverse();
    }

    function expanding2() {
      circleCursor.classList.add(expandClass2);
      expandBig.play();
    }

    function expanded2() {
      circleCursor.classList.remove(expandClass2);
      expandBig.reverse(.5);
    }

    function expanding3() {
      circleCursor.classList.add(expandClass3);
      expandBig.play();
    }

    function expanded3() {
      circleCursor.classList.remove(expandClass3);
      expandBig.reverse(.5);
    }
    expandCursor.forEach((expander)=>{
      expander.addEventListener('mouseenter',expanding);
      expander.addEventListener('mouseleave',expanded);
    });

    slideCursor.forEach((slider)=>{
      slider.addEventListener('mouseenter',expanding2);
      slider.addEventListener('mouseleave',expanded2);
    })

    bigCursor.forEach((btn)=>{
      btn.addEventListener('mouseenter',expanding3);
      btn.addEventListener('mouseleave',expanded3);
    })
*/
    const progNum = document.getElementsByClassName('progress-num')[0];

    const progTl = gsap.timeline();
    progTl.to('.progress-bg',{
      scrollTrigger:{
        trigger: ".smooth-scroll",
        scroller: ".smooth-scroll",
        start: "top top",
        end: "bottom bottom",
        scrub:true,
        onUpdate: self => progNum.innerHTML = Math.floor((self.progress)*100)
      },
      rotate:360,
      ease:'none'
    });

    const counselOpen = Array.from(document.getElementsByClassName('open-form-box'));
    const formContent = Array.from(document.getElementsByClassName('form-content'));

    let a = [false,false];

    counselOpen.forEach((btn,index)=>{
      btn.addEventListener(clickEvent,(e)=>{
        e.preventDefault();
        btn.parentNode.parentNode.classList.add('open-counsel');
        formContent.forEach((el)=>{gsap.to(el,{autoAlpha:0});});
        gsap.to(formContent[index],{autoAlpha:1});

        counselOpen.forEach((el)=>{el.classList.remove('active');});
        counselOpen[index].classList.add('active');
        if(a[index] == false){
          var i;
          for(i = 0; i < a.length; i++){
            a[i] = false;
          }
          a[index] = true;
        } else{
          btn.parentNode.parentNode.classList.remove('open-counsel');
          var i;
          for(i = 0; i < a.length; i++){
            a[i] = false;
          }
          
        }
      });

      
    });

    const doctorSect = document.getElementsByClassName('doctors-network-sect')[0];
    const doctorTits = document.querySelectorAll('.doctors-title-bg > div');
    
    const netLoc = document.getElementsByClassName('network-location-sect')[0];
    if(netLoc){
      var netLocTl = gsap.timeline({
        //paused:true,
        
        scrollTrigger: {
          trigger: netLoc,
          scroller: ".smooth-scroll",
          start: "top 100px", // when the top of the trigger hits the top of the viewport
          end: "+=100%", // end after scrolling 500px beyond the start
          //scrub: true,
          //pin:true

        }
      });
      netLocTl.fromTo('.seoul-network-location', {
          autoAlpha: 0,
          xPercent:-30,
          scale: 1.5
        }, {
          duration:.4,
          autoAlpha: 1,
          xPercent:0,
          scale: 1
        }, 0)
        .fromTo('.gyeonggi-network-location', {
          autoAlpha: 0,
          xPercent:30,
          scale: 1.5
        }, {
          duration:.4,
          autoAlpha: 1,
          xPercent:0,
          scale: 1
        }, 0.2)
        .fromTo('.honam-network-location', {
          autoAlpha: 0,
          scale: 1.5
        }, {
          duration:.4,
          autoAlpha: 1,
          scale: 1
        }, 0.3);


      ScrollTrigger.create({
        trigger:'.smooth-scroll',
        start: '100px top',
        end: 99999,
        scroller: ".smooth-scroll",
        //onEnter:()=>networkTl.play(),
        //onLeaveBack:()=>{networkTl.reverse()},
        toggleClass: {className: 'scrolled', targets: '.main-quick-menu'}
      })


    }
    // if(doctorSect){
    //   const doctorTl = gsap.timeline({
    //     scrollTrigger:{
    //       trigger:doctorSect,
    //       scroller: ".smooth-scroll",
    //       start:'top 33%',
    //     }
    //   });
    //   doctorTits.forEach((txt,i)=>{
    //     doctorTl.fromTo(txt,{opacity:0},{opacity:.15,duration:.3},i*0.15);
    //   });
    //   doctorTl.fromTo('.doctors-all-img',{
    //     yPercent:100,
    //     scale:1.25,
    //     autoAlpha:0
    //   },{
    //     ease:Power2.ease,
    //     duration:1.6,
    //     yPercent:0,
    //     scale:1,
    //     autoAlpha:1
    //   },.25);

    // }
    
    const slider = document.getElementsByClassName('js-slider')[0];
    const sliderOpen = document.getElementsByClassName('js-slider-open')[0];
    const sliderClose = document.getElementsByClassName('js-slider-close')[0];
    const sliderItem = Array.from(document.getElementsByClassName('js-item'));
    var sliderTl = gsap.timeline({paused:true});
    sliderTl.to(slider,{autoAlpha:1},0)
    .to(sliderOpen,{autoAlpha:0},0);

    sliderItem.forEach((item,index)=>{
      sliderTl.fromTo(item,{
        yPercent:50,
      },{
        yPercent:0,
        duration:.4,
        autoAlpha:1,
        delay:.1*index,
        ease:Power2.easeOut
      },0.2)
    });

    sliderOpen.addEventListener(clickEvent,(e)=>{
      sliderTl.play();
      eventSwiper.autoplay.start();
    });

    sliderClose.addEventListener(clickEvent,(e)=>{
      sliderTl.reverse(.2);
      eventSwiper.autoplay.stop();
    });
    if(mainPage){
      var options02 = {
        id: 554678771,
        width: 1920,
        background:true,
        quality:'720p'
      };
      var options03 = {
        id: 564009064,
        width: 1920,
        background:true,
        quality:'720p'
      };
      var options04 = {
        id: 564009159,
        width: 1920,
        background:true,
        quality:'720p'
      };
      //slide01Player = new Vimeo.Player('slideVideo01',options03);
      //slide02Player = new Vimeo.Player('slideVideo02',options04);
        
      //var mainPlayer = new Vimeo.Player('mainPlayer',options01);
      //mainPlayer2 = new Vimeo.Player('mainPlayer2',options02);
      //mainPlayer2.pause();
      //slide01Player.pause();
      //slide02Player.pause();
    }
    if(equipSect){
        //ScrollTrigger.create({
        //  trigger:'.equipment-sect',
        //  start: 'top 66%',
        //  end: 'bottom top',
        //  scroller: ".smooth-scroll",
        //  onEnter:()=>{
        //    mainPlayer2.setCurrentTime(6);
        //    mainPlayer2.play();
        //  },
        //  onLeave:()=>{
        //    mainPlayer2.pause();
        //  },
        //  onLeaveBack:()=>{},
        //});
    }
    

    const topBtn = document.getElementsByClassName("progress-area")[0];

    topBtn.addEventListener('mouseenter',(e)=>{
      gsap.to('.progress-percent',{autoAlpha:0, yPercent:-100});
      gsap.fromTo('.topbtn-up-arrow',{autoAlpha:0,yPercent:100},{autoAlpha:1, yPercent:0});
    });

    topBtn.addEventListener('mouseleave',(e)=>{
      gsap.to('.progress-percent',{autoAlpha:1, yPercent:0});
      gsap.fromTo('.topbtn-up-arrow',{autoAlpha:1,yPercent:0},{autoAlpha:0, yPercent:100});
    });
    
    topBtn.addEventListener(clickEvent,(e)=>{
      e.preventDefault();
      const target = document.getElementById('content');
      locoScroll.scrollTo(target);
    });
      
      
    /* 세로 스크롤 시 컨텐츠 가로 이동 (sub1_1) */
    const process = document.querySelector('.process');
    let sections = gsap.utils.toArray('.process .process__item');
    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".process-wrap",
        markers: false,
        scrub: 1,
        pin: true,
        //snap: 1 / (sections.length - 1),
        start: "top 5%",
        end: "+=4000",
      },
    });
    /* //세로 스크롤 시 컨텐츠 가로 이동 (sub1_1) */

  },

  // mobile
  "(max-width: 800px)": function() {
    // The ScrollTriggers created inside these functions are segregated and get
    // reverted/killed when the media query doesn't match anymore. 
    const mainVisual = new Swiper('.main-visual', {
      speed:800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop:true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    });

    let menuItem = document.querySelectorAll('.gnb-menu-depth1');

    function addClass(elem, className) {
      let max = elem.length;
      for (let i = 0; i < max; i++) {
        elem[i].classList.add(className);
      }
    }

    function removeClass(elem, className) {
      let max = elem.length;
      for (let i = 0; i < max; i++) {
        elem[i].classList.remove(className);
      }
    }

    menuItem.forEach(menu => {
      menu.addEventListener('click', function (e) {
        this.tg = e.target;
        this.menuList = this.tg.parentNode.parentNode;
        
        if (this.tg.nextElementSibling.classList.contains('gnb-menu-depth2')) {
          e.preventDefault();
          if (this.menuList.classList.contains('on')) {
            //클릭한 메뉴에 active가 있을때
            //removeClass(menuItem, 'on');
            this.menuList.classList.remove('on');
            gsap.fromTo(this.menuList, {
              height: (this.tg.offsetHeight + this.tg.nextElementSibling.offsetHeight) + 30,
            }, {
              height: this.tg.offsetHeight,
              duration: .4
            });
          } else {
            //클릭한 메뉴에 이미 on이 없을때
            removeClass(menuItem, 'on');
            this.menuList.classList.add('on');
            menuItem.forEach((el,index)=>{
              gsap.to(el, {
                height: this.tg.offsetHeight,
                duration: .4
              });
            });
            gsap.to(this.menuList, {
              height: (this.tg.offsetHeight + this.tg.nextElementSibling.offsetHeight) + 30,
              duration: .4
            });
          }

        }

      })
    });

    const mQuickCall = document.getElementsByClassName('m-quick-call')[0];
    const mQuickCircle = document.getElementsByClassName('m-quick-circle-wrap')[0];
    const mQuickOpen = document.getElementsByClassName('js-call-m-quick')[0];
    const mQuickClose = Array.from(document.getElementsByClassName('js-close-m-quick'));
    const mQuickBtns = Array.from(document.getElementsByClassName('m-quick-btn'));

    var mquickTl = gsap.timeline({paused:true});
    mquickTl.fromTo('.m-quick-dim-bg',{autoAlpha:0},{autoAlpha:1,duration:0.3},0).fromTo('.m-quick-circle-inner',{autoAlpha:0,rotate:100,scale:0.3},{autoAlpha:1,rotate:0,scale:1,duration:0.2},0.1)
    mQuickBtns.forEach((el)=>{mquickTl.fromTo(el,{autoAlpha:0},{autoAlpha:1},0.2)});

    if(getCookie('entering') !== 'Y'){
      console.log('처음 접속시');
      setCookie('entering', 'Y', 1);
      mQuickCall.classList.add('ani-start');
    }
    
    mQuickOpen.addEventListener(clickEvent,()=>{
      mQuickCircle.classList.add('open');
      mquickTl.play();
    });
    mQuickClose.forEach((close)=>{
      close.addEventListener(clickEvent,()=>{
        mQuickCircle.classList.remove('open');
        mquickTl.reverse();
      });
    });


    const mNetModal = document.getElementsByClassName('js-network-modal')[0];
    const mQuickOpen2 = Array.from(document.getElementsByClassName('js-call-m-net'));
    const mQuickClose2 = Array.from(document.getElementsByClassName('js-close-m-net'));

    mQuickOpen2.forEach((open)=>{
      open.addEventListener(clickEvent,(e)=>{
        e.preventDefault();
        mNetModal.classList.add('open');
        mQuickCircle.classList.remove('open');
        mquickTl.reverse();
        
        scrollPosition = window.pageYOffset;
        $('html, body').css({'overflow-y': 'hidden'}); // 모달팝업 중 html,body의 scroll을 hidden시킴 
        body.style.top = `-${scrollPosition}px`;
      });
    })
    mQuickClose2.forEach((close)=>{
      close.addEventListener(clickEvent,(e)=>{
        e.preventDefault();
        mNetModal.classList.remove('open');
        
        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
        window.scrollTo(0, scrollPosition);
      });
    });

    
    const networkTab = document.querySelectorAll('.js-network-tablist > li');
    const networkLoc = Array.from(document.getElementsByClassName('js-network-tabcnt'));
    
    if(networkTab){
      networkLoc.forEach((location,idx)=>{
        gsap.set(location,{autoAlpha:0});
        gsap.set(networkLoc[0],{autoAlpha:1});
      });
      
      networkTab.forEach((tablist,idx)=>{
        tablist.addEventListener(clickEvent,(e)=>{
          networkTab.forEach((el)=>{el.classList.remove('active')});
          networkTab[idx].classList.add('active');

          networkLoc.forEach((el)=>{gsap.to(el,{autoAlpha:0});});
          gsap.to(networkLoc[idx],{autoAlpha:1});
          
        });
      })
      
    }

    const mCallCounsel = document.getElementsByClassName('js-call-m-counsel')[0];
    const mCloseCounsel = document.getElementsByClassName('counsel--call-btn')[0];
    const mCounselModal = document.getElementsByClassName('counsel-area')[0];
    mCallCounsel.addEventListener(clickEvent,(e)=>{
      e.preventDefault();
      mQuickCircle.classList.remove('open');
      mquickTl.reverse();
      mCounselModal.classList.add('m-open');
      gsap.to('.counsel-dim',{autoAlpha:1});
    });

    mCloseCounsel.addEventListener(clickEvent,(e)=>{
      e.preventDefault();
      mCounselModal.classList.remove('m-open');
      gsap.to('.counsel-dim',{autoAlpha:0});
    });
      
      
      
    /* 세로 스크롤 시 컨텐츠 가로 이동 (sub1_1) */
    const process = document.querySelector('.process');
    let sections = gsap.utils.toArray('.process .process__item');
    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".process-wrap",
        markers: false,
        scrub: 1,
        pin: true,
        //snap: 1 / (sections.length - 1),
        start: "top top",
        end: "+=3000",
      },
    });
    /* //세로 스크롤 시 컨텐츠 가로 이동 (sub1_1) */

  },
    
    
  "(max-width: 480px)": function() {
    
    /* 세로 스크롤 시 컨텐츠 가로 이동 (sub1_1) */
    const process = document.querySelector('.process');
    let sections = gsap.utils.toArray('.process .process__item');
    gsap.fromTo(sections, {
      xPercent: 0,
      ease:'linear',
    },{
      scrollTrigger: {
        trigger: ".process-wrap",
        markers: false,
        scrub: 1,
        pin: true,
        //snap: 1 / (sections.length - 1),
        start: "top top",
        end: "+=3000",
      },
      xPercent: -110 * (sections.length - 1),
      ease:'linear',
    });
    /* //세로 스크롤 시 컨텐츠 가로 이동 (sub1_1) */
    
  },
    
	
  // all 
  "all": function() {
    // ScrollTriggers created here aren't associated with a particular media query,
    // so they persist.
    const cntStart = document.getElementById('startContent');

    var networkTl = gsap.timeline({paused:true});
    networkTl.fromTo('.network-select-list',{y:0, autoAlpha:1},{y:0,autoAlpha:0});

    ScrollTrigger.create({
      trigger:cntStart,
      start: 'bottom 20%',
      end: 99999,
      scroller: ".smooth-scroll",
      onEnter:()=>networkTl.play(),
      onLeaveBack:()=>{networkTl.reverse()},
      toggleClass: {className: 'scrolled', targets: '.main-header,.hamberger,.m-network-call-icon,.sub-gnb-bg'}
    });


    const changeHeader = document.getElementsByClassName('change-header')[0];
    if(changeHeader){
      header.classList.add('bk-header');
      hamberger.classList.add('bk-header');
      netCall.classList.add('bk-header');
      ScrollTrigger.create({
        trigger: changeHeader,
        scroller: ".smooth-scroll",
        start: "top top",
        end: 'bottom top',
        onEnter: () => {
            header.classList.add('bk-header');
            hamberger.classList.add('bk-header');
            netCall.classList.add('bk-header');
          },
          onEnterBack: () => {
            header.classList.add('bk-header');
            hamberger.classList.add('bk-header');
            netCall.classList.add('bk-header');
          },
          onLeave: () => {
            header.classList.remove('bk-header');
            hamberger.classList.remove('bk-header');
            netCall.classList.remove('bk-header');
          },
        //toggleClass: {className: 'bk-header', targets: '.main-header'}
      });
    }

    

    const countSect = document.getElementsByClassName('doctors-count-sect')[0];
    if(countSect){
      //하단 카운팅 효과
      gsap.fromTo('.doctors-count-bg .bg', {
        autoAlpha: 0.8,
        scale: 1.2
      }, {
        scrollTrigger:{
          trigger:countSect,
          start:'top center',
          scroller: ".smooth-scroll",
          //markers:true
        },
        duration:5,
        autoAlpha: 1,
        scale: 1,
        ease:'none'
      });

      //숫자 올라가는 효과 기본값 설정
      window.odometerOptions = {
        auto: false, // Don't automatically initialize everything with class 'odometer'
        format: '(,ddd).dd', // Change how digit groups are formatted, and how many digits are shown after the decimal point
        duration: 2000, // Change how long the javascript expects the CSS animation to take
      };

      //숫자 올라가는 효과 설정
      let odoelem = document.querySelectorAll('.doctors-counter-num');
      odoelem.forEach((elem) => {
        let num = elem.getAttribute('data-counter');
        let odotl = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            scroller: ".smooth-scroll",
            start: "top 70%", // when the top of the trigger hits the top of the viewport
            end: "+=500", // end after scrolling 500px beyond the start
            onEnter: () => {
              var od = new Odometer({
                el: elem,
                //value: 0,
                format: '(,ddd).dd',
                duration: 1600
              });
              od.update(num);
            }

          }
        })
      });

    }

    const mainEquip = document.getElementsByClassName('main-equipment-sect')[0];
    const searchSect = document.getElementsByClassName('doctors-search-sect')[0];
    if(mainEquip){
      ScrollTrigger.create({
        trigger:mainEquip,
        scroller:".smooth-scroll",
        start: 'top bottom',
        onEnter:()=>{laserEquip.autoplay.start()}
      });

      ScrollTrigger.create({
        trigger:searchSect,
        scroller:".smooth-scroll",
        start: 'top 70%',
        onEnter:()=>searchSect.classList.add('is-active')
      });
    }

    const revealAni = document.getElementsByClassName("gs_reveal");
    if(revealAni){
      gsap.utils.toArray(revealAni).forEach(function(elem, index){
        gsap.set(elem, {autoAlpha: 0}); // assure that the element is hidden when scrolled into view
        const getDelay = elem.getAttribute('data-gs-delay');
      
        var x = 0,
          y = 60,
          z = -100;
      
        if (elem.classList.contains('reveal_fromDown')) {
          y = -12
        } else if(elem.classList.contains("reveal_fromUp")){
          y = 12;
        } else if (elem.classList.contains("reveal_fromRight")) {
          x = 30;
          y = 0;
          z = 10;
        } else if (elem.classList.contains("reveal_fromLeft")) {
          x = -300;
          y = 0;
          z = 10;
        } 
        gsap.fromTo(elem, {
          x: x,
          y: y,
          z: z,
          autoAlpha: 0
        }, {
          scrollTrigger: {
            trigger: elem.parentNode,
            scroller: ".smooth-scroll",
            start: 'top 50%',
            toggleActions: 'play none none none',
            onEnter:()=>elem.classList.add('active'),
            //toggleClass: {className: 'waypoint', targets: '.main-header, #gnb'}
            //markers:true
          },
          duration: .8,
          x: 0,
          y: 0,
          z: 0,
          autoAlpha: 1,
          ease: Power2.easeOut,
          overwrite: "auto",
          delay: getDelay * 0.001
        });
      });
    }/* gs_reveal이 있을때만 실행 */

    const pintitle = document.getElementsByClassName('pin-title-start')[0];
    if(pintitle){
      gsap.to('.pin-title-start',{
        scrollTrigger:{
          trigger:'.pin-title-end',
          start: 'top 240',
          end: 99999,
          scroller: ".smooth-scroll",
          toggleClass: {className: 'sticky', targets: '.pin-title-start,.pin-title-end'}
        },
        //autoAlpha:0
      })
      
    }

    const waypoint = document.getElementsByClassName("waypoint");
    if(waypoint){
      gsap.utils.toArray(waypoint).forEach(function(elem, index){
        var startPoint = elem.getAttribute('data-start');
        var startTrigger = startPoint !== null ? startPoint : 'top 50%';

        ScrollTrigger.create({
          trigger: elem,
          scroller: ".smooth-scroll",
          start: startTrigger,
          end: 'bottom top',
          toggleActions: 'play none none none',
          onEnter:()=>elem.classList.add('active'),
          //markers:true,
          //toggleClass: {className: 'active', targets: elem}
        });
      });
    }/* waypoint가 있을때만 실행 */

    gsap.utils.toArray(".sub-visual-sect").forEach((section, i) => {
      section.bg = section.querySelector(".bg");
      section.txt = section.querySelector(".visual-title");
      let elemHeight = section.bg.clientHeight;
      section.bg.style.backgroundPosition = "50% 0px"; 
      gsap.to(section.bg, {
        backgroundPosition: `50% ${elemHeight / 2}px`,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          scroller: ".smooth-scroll",
          start: "top top", 
          end: "bottom top",
          scrub: true
        }
      });
      gsap.to(section.txt, {
        top: '100%',
        autoAlpha:0,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          scroller: ".smooth-scroll",
          start: "top top",
          end: "bottom top",
          scrub: true
        },
      });
    });
  }
	
});

// each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

// after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
ScrollTrigger.refresh();




const eventSlide = new Swiper('.event-btn-slide', {
  speed:1000,
  autoplay: {
    delay: 1000,
    disableOnInteraction: false,
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
});

/* 탑배너로 인해 추가 210708 */
var topbnroption = {
  speed:1000,
  autoplay: {
    delay: 4000,
  },
  direction: 'vertical',
}
const topBnrSlide = new Swiper('.top-bnr-slider', topbnroption);
const mtopBnrSlide = new Swiper('.m-top-bnr-slider', topbnroption);
/* 탑배너로 인해 추가 210708 */

const bestReviewSect = document.getElementsByClassName('best-review-slide-wrap')[0];
if(bestReviewSect){
  const bestReviewSlide = new Swiper('.best-review-slide', {
    speed:800,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 10,
    navigation: {
      nextEl: '.review-ctrls.next',
      prevEl: '.review-ctrls.prev',
    },
    breakpoints: {
      // when window width is >= 640px
      640: {
        slidesPerView: 1.2,
        //spaceBetween: 10
      },
      // when window width is >= 640px
      1024: {
        slidesPerView: 2,
        //spaceBetween: 10
      },
      // when window width is >= 640px
      1360: {
        slidesPerView: 2.4,
        //spaceBetween: 5
      },
      // when window width is >= 640px
      1440: {
        slidesPerView: 2.6,
        //spaceBetween: 5
      },
      // when window width is >= 640px
      1680: {
        slidesPerView: 3,
        //spaceBetween: 10
      }
    }
  });
  ScrollTrigger.create({
    trigger: bestReviewSect,
    scroller: ".smooth-scroll",
    start: 'top bottom',
    end: 'bottom top',
    toggleActions: 'play none none none',
    onEnter:()=>{
      bestReviewSect.classList.add('active');
      bestReviewSlide.autoplay.start();
    },
    //markers:true,
    //toggleClass: {className: 'active', targets: elem}
  });
  bestReviewSlide.autoplay.stop();
}

const footerCase = new Swiper('.sub-foot-case-slide', {
  speed:800,
  spaceBetween:10,
  loop:true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.sub-foot-case-area .ctrls-foot.next',
    prevEl: '.sub-foot-case-area .ctrls-foot.prev',
  },
});

const footerEvent = new Swiper('.sub-foot-event-slide', {
  speed:800,
  spaceBetween:10,
  loop:true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.sub-foot-event-area .ctrls-foot.next',
    prevEl: '.sub-foot-event-area .ctrls-foot.prev',
  },
});

const footerNetwork = new Swiper('.footer-network-infos', {
  speed:800,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  direction:'vertical'
});

var laserPagiList = ['리프팅','색소치료','여드름치료','스컬트라','손발톱무좀','백반증/건선'];
const laserEquip = new Swiper('.main-equipment-slider', {
  // Default parameters
  speed:800,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  slidesPerView: 1,
  spaceBetween: 0,
  centeredSlides:true,
  loop:true,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  parallax: true,
  pagination: {
    el: '.swiper-pagination.laser-pagi',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + laserPagiList[index] + '</span>';
    },
  },
  navigation: {
    nextEl: '.laser-ctrls.next',
    prevEl: '.laser-ctrls.prev',
  },
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 640px
    1024: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    // when window width is >= 640px
    1360: {
      slidesPerView: 1.2,
      spaceBetween: 0
    },
    // when window width is >= 640px
    1440: {
      slidesPerView: 1.4,
      spaceBetween: 0
    },
    // when window width is >= 640px
    1680: {
      slidesPerView: 1.6,
      spaceBetween: 0
    }
  }
});
laserEquip.autoplay.stop();

const netMarkers = Array.from(document.getElementsByClassName('network-marker'));

netMarkers.forEach((marker)=>{
  marker.addEventListener('mouseenter',(event)=>{
    const tgParent = event.target.parentNode;
    const txt = tgParent.getElementsByClassName('network-point-txt')[0];
    const img = tgParent.getElementsByClassName('network-point-profiles')[0];
    tgParent.classList.add('marker-hover');
    gsap.fromTo(txt,{y:20,autoAlpha:0},{duration:.4,y:0,autoAlpha:1});
    gsap.fromTo(img,{y:20,autoAlpha:0},{duration:.4,y:0,autoAlpha:1});
  });
  marker.addEventListener('mouseleave',(event)=>{
    const tgParent = event.target.parentNode;
    const txt = tgParent.getElementsByClassName('network-point-txt')[0];
    const img = tgParent.getElementsByClassName('network-point-profiles')[0];
    tgParent.classList.remove('marker-hover');
    gsap.fromTo(txt,{y:0,autoAlpha:1},{duration:.4,y:20,autoAlpha:0});
    gsap.fromTo(img,{y:0,autoAlpha:1},{duration:.4,y:20,autoAlpha:0});
  });
});

const sliderScroll = document.getElementsByClassName('slider__container-scroll')[0];

const eventSwiper = new Swiper(sliderScroll, {
  slidesPerView: 2,
  spaceBetween: 10,
  mousewheel:true,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
    dragSize:100,
  },
  //freeMode:true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2.2,
      spaceBetween: 20
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3.3,
      spaceBetween: 30
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4.4,
      spaceBetween: 45,
      scrollbar:{
        dragSize:300,
      }
    }
  }
});

const subLaser = new Swiper('.sub-laser-slider', {
  slidesPerView: 2.4,
  spaceBetween: 10,
  scrollbar: {
    el: '.sub-laser-sect .swiper-scrollbar',
    draggable: true,
    dragSize:60,
  },
  //freeMode:true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2.4,
      spaceBetween: 10
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3.6,
      spaceBetween: 15
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
      spaceBetween: 20,
      scrollbar: {
        dragSize:148
      }
    },
    // when window width is >= 640px
    1024: {
      slidesPerView: 5.3,
      spaceBetween: 20,
      scrollbar: {
        dragSize:148
      }
    }
  }
});

const subLaser2 = new Swiper('.sub-laser-right-slider', {
  slidesPerView: 2,
  spaceBetween: 10,
  scrollbar: {
    el: '.sub-laser-align-right .swiper-scrollbar',
    draggable: true,
    dragSize:10,
  },
  //freeMode:true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2.4,
      spaceBetween: 15
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3.5,
      spaceBetween: 20,
      scrollbar: {
        dragSize:148
      }
    }
  }
});


const tabSliders = document.querySelectorAll('div[class*=tab-slider-num]');
if(tabSliders){
  tabSliders.forEach((slider,index)=>{
    const tabSlide = new Swiper(slider, {
      //mousewheel:true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      observer: true,
      observeParents: true,
      
    });
  });
}

/* <!-- 210701 수정 --> */
const tabSliders2 = document.querySelectorAll('div[class*=sub1-3-tab-slider-num]');
if(tabSliders2){
  tabSliders2.forEach((slider,index)=>{
    const tabSlide = new Swiper(slider, {
      speed:700,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      autoplayDisableOnInteraction: false,
      loop: true,
      //mousewheel:true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      observer: true,
      observeParents: true,
      
    });
  });
}
/* <!-- 210701 수정 --> */

const subTabSect = document.getElementsByClassName('sub-tab-sect')[0];
const subTabList = document.querySelectorAll('.sub-tab-links > li');
if(subTabSect){
  gsap.utils.toArray('.tabcontent').forEach(function(elem,idx){
    gsap.set(elem,{autoAlpha:0});
  });
  gsap.set('.tabcontent:first-child',{autoAlpha:1});
  subTabList.forEach((elem,index)=>{
    elem.addEventListener(clickEvent,(e)=>{
      e.preventDefault();
      var evt = e;
      var cityName = elem.getElementsByTagName('a')[0].getAttribute('href');
      openTab(evt, cityName);
    });
  });
  //subTabList[0].click();
}


function openTab(evt,cityName) {
  // Declare all variables
  var i, tabcontent, tablinks;

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    gsap.to(tabcontent[i],{autoAlpha:0});
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  gsap.to(cityName,{autoAlpha:1});
  evt.currentTarget.className += " active";
}

const subTabSect41 = document.getElementsByClassName('sub-tab-sect')[0];
const subTabList41 = document.querySelectorAll('.sub-tab-links41 > li');
if(subTabSect41){
  gsap.utils.toArray('.tabcontent41').forEach(function(elem,idx){
    gsap.set(elem,{autoAlpha:0});
  });
  gsap.set('.tabcontent41:first-child',{autoAlpha:1});
  subTabList41.forEach((elem,index)=>{
    elem.addEventListener(clickEvent,(e)=>{
      e.preventDefault();
      var evt = e;
      var cityName = elem.getElementsByTagName('a')[0].getAttribute('href');
      openTab41(evt, cityName);
    });
  });
  //subTabList[0].click();
}


function openTab41(evt,cityName) {
  // Declare all variables
  var i, tabcontent, tablinks;

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("tabcontent41");
  for (i = 0; i < tabcontent.length; i++) {
    gsap.to(tabcontent[i],{autoAlpha:0});
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.getElementsByClassName("tablinks41");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active41", "");
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  gsap.to(cityName,{autoAlpha:1});
  evt.currentTarget.className += " active41";
}


const subTabSect22 = document.getElementsByClassName('sub-tab-sect2-2')[0];
const subTabList22 = document.querySelectorAll('.sub-tab-links2-2 > li');
if(subTabSect22){
  gsap.utils.toArray('.tabcontent2-2').forEach(function(elem,idx){
    gsap.set(elem,{autoAlpha:0});
  });
  gsap.set('.tabcontent2-2:first-child',{autoAlpha:1});
  subTabList22.forEach((elem,index)=>{
    elem.addEventListener(clickEvent,(e)=>{
      e.preventDefault();
      var evt = e;
      var cityName = elem.getElementsByTagName('a')[0].getAttribute('href');
      openTab22(evt, cityName);
    });
  });
  //subTabList[0].click();
}

const mainPopUpModalClose = Array.from(document.getElementsByClassName('main-popup-close-modal-btn'));
const mainPopUpModalClosebg = Array.from(document.getElementsByClassName('main-popup-modal-item-bg'));
if(mainPopUpModalClose){
    mainPopUpModalClose.forEach((button)=>{
        button.addEventListener('click',(event)=>{
          document.querySelector('.main-popup-modal-item').style.visibility = 'hidden';
          document.querySelector('.main-popup-modal-item-bg').style.visibility = 'hidden';
          document.querySelector('.stp-btn').style.display = 'none';
        });
    });
    
    mainPopUpModalClosebg.forEach((button)=>{
        button.addEventListener('click',(event)=>{
          document.querySelector('.main-popup-modal-item').style.visibility = 'hidden';
          document.querySelector('.main-popup-modal-item-bg').style.visibility = 'hidden';
          document.querySelector('.stp-btn').style.display = 'none';
        });
    });
}
const main_m_PopUpModalClose = Array.from(document.getElementsByClassName('main-m-popup-close-modal-btn'));
const main_m_PopUpModalClosebg = Array.from(document.getElementsByClassName('main-m-popup-modal-item-bg'));
if(main_m_PopUpModalClose){
    main_m_PopUpModalClose.forEach((button)=>{
        button.addEventListener('click',(event)=>{
          document.querySelector('.main-m-popup-modal-item').style.visibility = 'hidden';
          document.querySelector('.main-m-popup-modal-item-bg').style.visibility = 'hidden';
          document.querySelector('.m-stp-btn').style.display = 'none';
        });
    });
    
    main_m_PopUpModalClosebg.forEach((button)=>{
        button.addEventListener('click',(event)=>{
          document.querySelector('.main-m-popup-modal-item').style.visibility = 'hidden';
          document.querySelector('.main-m-popup-modal-item-bg').style.visibility = 'hidden';
          document.querySelector('.m-stp-btn').style.display = 'none';
        });
    });
}
/*
const bullet = ['내성제로 제오민 보톡스', '전 지점 올리지오 EVENT', '여드름 근본치료, 아크네골드', '고덕점 COMING SOON'];
const mainPopUp = new Swiper('.main-popup-modal-slider', {
  speed:800,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  autoplayDisableOnInteraction: false,
  loop: true,
  loopAdditionalSlides: 1,
  centeredSlides : true,
  observer: true,
  observeParents: true,
  pagination: {
    el: '.swiper-pagination.main-popup-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + '"><span>' + (bullet[index]) + '</span></div>';
    }
  },
  
});
*/



function openTab22(evt,cityName) {
  // Declare all variables
  var i, tabcontent22, tablinks22;

  // Get all elements with class="tabcontent" and hide them
  tabcontent22 = document.getElementsByClassName("tabcontent2-2");
  for (i = 0; i < tabcontent22.length; i++) {
    gsap.to(tabcontent22[i],{autoAlpha:0});
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks22 = document.getElementsByClassName("tablinks2-2");
  for (i = 0; i < tablinks22.length; i++) {
    tablinks22[i].className = tablinks22[i].className.replace(" active2-2", "");
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  gsap.to(cityName,{autoAlpha:1});
  evt.currentTarget.className += " active2-2";
}

/* 210625 수정 */
function openTab2(evt, tabName) {
  var i, tabcontent2, tablinks2;
  tabcontent2 = document.getElementsByClassName("tabcontent2");
  for (i = 0; i < tabcontent2.length; i++) {
    tabcontent2[i].style.display = "none";
  }
  tablinks2 = document.getElementsByClassName("tablinks2");
  for (i = 0; i < tablinks2.length; i++) {
    tablinks2[i].className = tablinks2[i].className.replace(" active2", "");
  }
  document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " active2";
}

const revealAni2 = document.getElementsByClassName("gs_reveal_sub");
if(revealAni2){
gsap.utils.toArray(revealAni2).forEach(function(elem, index){
  gsap.set(elem, {autoAlpha: 0}); // assure that the element is hidden when scrolled into view
  const getDelay2 = elem.getAttribute('data-gs-delay');

  var x = 0,
    y = 60,
    z = -100;

  if (elem.classList.contains('reveal_fromDown_sub')) {
    y = -60
  } else if(elem.classList.contains("reveal_fromUp_sub")){
    y = 12;
  } else if (elem.classList.contains("reveal_fromRight_sub")) {
    x = 30;
    y = 0;
    z = 10;
  } else if (elem.classList.contains("reveal_fromLeft_sub")) {
    x = -300;
    y = 0;
    z = 10;
  } 
  gsap.fromTo(elem, {
    x: x,
    y: y,
    z: z,
    autoAlpha: 0
  }, {
    scrollTrigger: {
      trigger: elem.parentNode,
      scroller: ".smooth-scroll",
      start: 'top 50%',
      toggleActions: 'play none none none',
      onEnter:()=>elem.classList.add('active'),
      //toggleClass: {className: 'waypoint', targets: '.main-header, #gnb'}
      //markers:true
    },
    duration: .8,
    x: 0,
    y: 0,
    z: 0,
    autoAlpha: 1,
    ease: Power2.easeOut,
    overwrite: "auto",
    delay: getDelay2 * 0.001
  });
});
}/* gs_reveal_sub이 있을때만 실행 */

const callSpecialistModal5 = Array.from(document.getElementsByClassName('tablinks2'));

if(callSpecialistModal5){
callSpecialistModal5.forEach((button)=>{
  button.addEventListener(clickEvent,(event)=>{
      const revealAni2 = document.getElementsByClassName("gs_reveal_sub");
      if(revealAni2){
        gsap.utils.toArray(revealAni2).forEach(function(elem, index){
          gsap.set(elem, {autoAlpha: 0}); // assure that the element is hidden when scrolled into view
          const getDelay2 = elem.getAttribute('data-gs-delay');

          var x = 0,
            y = 60,
            z = -100;

          if (elem.classList.contains('reveal_fromDown_sub')) {
            y = -60
          } else if(elem.classList.contains("reveal_fromUp_sub")){
            y = 12;
          } else if (elem.classList.contains("reveal_fromRight_sub")) {
            x = 30;
            y = 0;
            z = 10;
          } else if (elem.classList.contains("reveal_fromLeft_sub")) {
            x = -300;
            y = 0;
            z = 10;
          } 
          gsap.fromTo(elem, {
            x: x,
            y: y,
            z: z,
            autoAlpha: 0
          }, {
            scrollTrigger: {
              trigger: elem.parentNode,
              scroller: ".smooth-scroll",
              start: 'top 50%',
              toggleActions: 'play none none none',
              onEnter:()=>elem.classList.add('active'),
              //toggleClass: {className: 'waypoint', targets: '.main-header, #gnb'}
              //markers:true
            },
            duration: .8,
            x: 0,
            y: 0,
            z: 0,
            autoAlpha: 1,
            ease: Power2.easeOut,
            overwrite: "auto",
            delay: getDelay2 * 0.001
          });
        });
      }/* gs_reveal_sub이 있을때만 실행 */
  })
});
}

/** 팝업 시 스크롤 막기 , 팝업 시 스크롤 위치 저장 **/
const body = document.querySelector('body');
let scrollPosition = 0;
/** //팝업 시 스크롤 막기 , 팝업 시 스크롤 위치 저장 **/

//const callSpecialistModal3 = Array.from(document.getElementsByClassName('tablinks22'));
//const closeSpecialistModal3 = Array.from(document.getElementsByClassName('sub3-1-close-modalevent-btn'));
//const closeSpecialistModalItem3 = Array.from(document.getElementsByClassName('sub3-1-modal-event-item_dim'));
//
//if(callSpecialistModal3){
//    callSpecialistModal3.forEach((button)=>{
//      button.addEventListener(clickEvent,(event)=>{
//        document.querySelector('.sub3-1-modal-event-item').style.visibility = 'inherit';
//          const sinsanetevent = new Swiper('.sub1-3-sinsa-modal-slider-event', {
//            slidesPerView: 'auto',
//            spaceBetween: 20,
//            speed:800,
//            autoplay: {
//              delay: 3000,
//            },
//            autoplayDisableOnInteraction: false,
//            loop: true,
//            loopAdditionalSlides: 1,
//            centeredSlides : true,
//            grabCursor: true,
//            navigation: {
//              nextEl: '.sinsa-event-ctrls.next',
//              prevEl: '.sinsa-event-ctrls.prev',
//            },
//            observer: true,
//            observeParents: true,
//            breakpoints:{
//              320:{
//                slidesPerView: 1,
//              },
//              590:{
//                slidesPerView: 1.2,
//              },
//              640:{
//                slidesPerView: 1.3,
//              },
//              800:{
//                slidesPerView: 2,
//              },
//              1024:{
//                slidesPerView: 2.1,
//              },
//              1025:{
//                slidesPerView: 3,
//              },
//              1550:{
//                slidesPerView: 3.05,
//              }
//            }
//        });
//
//        scrollPosition = window.pageYOffset;
//        $('html, body').css({'overflow-y': 'hidden'}); // 모달팝업 중 html,body의 scroll을 hidden시킴 
//        body.style.top = `-${scrollPosition}px`;
//      })
//    });
//    closeSpecialistModal3.forEach((button)=>{
//      button.addEventListener(clickEvent,(event)=>{
//        var modal = button.parentNode;
//        modal.style.visibility = 'hidden';
//
//        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
//        window.scrollTo(0, scrollPosition);
//      });
//    })
//    /*closeSpecialistModalItem3.forEach((button)=>{
//      button.addEventListener(clickEvent,(event)=>{
//        document.querySelector('.sub3-1-modal-event-item').style.visibility = 'hidden';
//        document.querySelector('.sub3-1-modal-event-item_dim').style.display = 'none';
//
//        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
//        window.scrollTo(0, scrollPosition);
//      });
//    })*/
//}

const callSpecialistModal2 = Array.from(document.getElementsByClassName('sub3-1-call-modal-btn'));
const closeSpecialistModal2 = Array.from(document.getElementsByClassName('sub3-1-close-modal-btn'));
const closeSpecialistModal22 = Array.from(document.getElementsByClassName('sub3-1-modal-item-bg'));
const sinsanet = new Swiper('.sub1-3-sinsa-modal-slider-wrap', {
  speed:800,
  autoplay: {
    delay: 1500,
    disableOnInteraction: false,
  },
  observer: true,
  observeParents: true,
});

if(callSpecialistModal2){
    callSpecialistModal2.forEach((button,index)=>{
      button.addEventListener('click',(event)=>{
        document.querySelector('.sub3-1-modal-item').style.display = 'block';
        document.querySelector('.sub3-1-modal-item-bg').style.display = 'block';
        
        sinsanet.slideTo(index, 10, false);
        
        scrollPosition = window.pageYOffset;
        $('html, body').css({'overflow-y': 'hidden'}); // 모달팝업 중 html,body의 scroll을 hidden시킴 
        body.style.top = `-${scrollPosition}px`;
      })
    });
    closeSpecialistModal2.forEach((button)=>{
      button.addEventListener('click',(event)=>{
        
        document.querySelector('.sub3-1-modal-item').style.display = 'none';
        document.querySelector('.sub3-1-modal-item-bg').style.display = 'none';

        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
        window.scrollTo(0, scrollPosition);
      });
    })
    closeSpecialistModal22.forEach((button)=>{
      button.addEventListener('click',(event)=>{
          
        document.querySelector('.sub3-1-modal-item').style.display = 'none';
        document.querySelector('.sub3-1-modal-item-bg').style.display = 'none';

        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
        window.scrollTo(0, scrollPosition);
      });
    })
}

const callSpecialistModal4 = Array.from(document.getElementsByClassName('net-sub1-3-sinsa-btn01'));
const closeSpecialistModal4 = Array.from(document.getElementsByClassName('sub3-1-close-modalmap-btn'));
const closeSpecialistModal44 = Array.from(document.getElementsByClassName('sub3-1-modal-mab-item-bg'));

if(callSpecialistModal4){
    callSpecialistModal4.forEach((button)=>{
      button.addEventListener('click',(event)=>{
        document.querySelector('.sub3-1-modal-mab-item').style.display = 'block';
        document.querySelector('.sub3-1-modal-mab-item-bg').style.display = 'block';

        scrollPosition = window.pageYOffset;
        $('html, body').css({'overflow-y': 'hidden'}); // 모달팝업 중 html,body의 scroll을 hidden시킴 
        body.style.top = `-${scrollPosition}px`;
      })
    });
    closeSpecialistModal4.forEach((button)=>{
      button.addEventListener('click',(event)=>{
        document.querySelector('.sub3-1-modal-mab-item').style.display = 'none';
        document.querySelector('.sub3-1-modal-mab-item-bg').style.display = 'none';

        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
        window.scrollTo(0, scrollPosition);
      });
    })
    closeSpecialistModal44.forEach((button)=>{
      button.addEventListener('click',(event)=>{
        document.querySelector('.sub3-1-modal-mab-item').style.display = 'none';
        document.querySelector('.sub3-1-modal-mab-item-bg').style.display = 'none';

        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
        window.scrollTo(0, scrollPosition);
      });
    })
}
/* // 210625 수정 */

/* 210624 수정 */
$('.sub1-3-mab-hotspot .sub1-3-mab-icon').click(function () {
var $parent = $(this).parent();
$parent.toggleClass('sub1-3-mab-open');
$('.sub1-3-mab-hotspot.sub1-3-mab-open').not($parent).removeClass('sub1-3-mab-open');
});

const chkallBtn2 = document.querySelector('input[name="ft_total_agree2"]');
const chkitems2 = document.querySelectorAll('.sub1-3-mab-privacy_cont input[type="checkbox"]');

if(chkallBtn2){
const pop2 = document.getElementsByClassName('sub1-3-mab-hotspot')[0];
const iconBox2 = document.getElementById('sub1-3-mab-iconBox');
chkallBtn2.addEventListener('change',function(){
  chkitems2.forEach((chkbox,index)=>{
    if(!chkbox.checked){
      chkbox.checked = true;
      iconBox2.classList.add('sub1-3-mab-checked');
    } else{
      chkbox.checked = false;
      iconBox2.classList.remove('sub1-3-mab-checked');
    }
    
  });
  pop2.classList.remove('sub1-3-mab-open');
  
});
}
/* // 210624 수정 */

/* 210727 수정 */
const callcounselpopupModal = Array.from(document.getElementsByClassName('counsel-popup-call-btn'));
const closecounselpopupModal = Array.from(document.getElementsByClassName('counsel-popup-close-modal-btn'));
const closecounselpopupModal2 = Array.from(document.getElementsByClassName('counsel-popup-modal-item-bg'));

if(callcounselpopupModal){
    callcounselpopupModal.forEach((button)=>{
      button.addEventListener('click',(event)=>{
        document.querySelector('.counsel-popup-modal-item').style.display = 'block';
        document.querySelector('.counsel-popup-modal-item-bg').style.display = 'block';

        scrollPosition = window.pageYOffset;
        $('html, body').css({'overflow-y': 'hidden'}); // 모달팝업 중 html,body의 scroll을 hidden시킴 
        body.style.top = `-${scrollPosition}px`;
      })
    });
    closecounselpopupModal.forEach((button)=>{
      button.addEventListener('click',(event)=>{
        document.querySelector('.counsel-popup-modal-item').style.display = 'none';
        document.querySelector('.counsel-popup-modal-item-bg').style.display = 'none';

        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
        window.scrollTo(0, scrollPosition);
      });
    })
    closecounselpopupModal2.forEach((button)=>{
      button.addEventListener('click',(event)=>{
        document.querySelector('.counsel-popup-modal-item').style.display = 'none';
        document.querySelector('.counsel-popup-modal-item-bg').style.display = 'none';

        $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
        window.scrollTo(0, scrollPosition);
      });
    })
}

$('.counsel-popup-hotspot .counsel-popup-icon').click(function () {
var $parent = $(this).parent();
$parent.toggleClass('counsel-popup-open');
$('.counsel-popup-hotspot.counsel-popup-open').not($parent).removeClass('counsel-popup-open');
});

const chkcounselpopupallBtn = document.querySelector('input[name="ft_total_agree3"]');
const chkcounselpopupitems = document.querySelectorAll('.counsel-popup-privacy_cont input[type="checkbox"]');

if(chkcounselpopupallBtn){
const popcounselpopup = document.getElementsByClassName('counsel-popup-hotspot')[0];
const iconBoxcounselpopup = document.getElementById('counsel-popup-iconBox');
chkcounselpopupallBtn.addEventListener('change',function(){
  chkcounselpopupitems.forEach((chkbox,index)=>{
    if(!chkbox.checked){
      chkbox.checked = true;
      iconBoxcounselpopup.classList.add('counsel-popup-checked');
    } else{
      chkbox.checked = false;
      iconBoxcounselpopup.classList.remove('counsel-popup-checked');
    }
    
  });
  popcounselpopup.classList.remove('counsel-popup-open');
  
});
}
/* //210727 수정 */

 /* 210624 수정 */
  const subcountSect = document.getElementsByClassName('sub1-1-sect05')[0];
  if(subcountSect){
    //하단 카운팅 효과
    gsap.fromTo('.sub1-1-sect05-bg .bg', {
      autoAlpha: 1,
      scale: 1.2
    }, {
      scrollTrigger:{
        trigger:subcountSect,
        start:'top center',
        scroller: ".smooth-scroll",
      },
      duration:5,
      autoAlpha: 1,
      scale: 1,
      ease:'none'
    });
  }
  /* //210624 수정 */


const callSpecialistModal = Array.from(document.getElementsByClassName('call-modal-btn'));
const closeSpecialistModal = Array.from(document.getElementsByClassName('close-modal-btn'));
const closeSpecialistModalBg = Array.from(document.getElementsByClassName('specialists-modal-img'));
const closeSpecialistModalItem = Array.from(document.getElementsByClassName('network-specialists-modal'));
const closeSpecialistLaserModalItem = Array.from(document.getElementsByClassName('laser-modal'));

if(callSpecialistModal){
  callSpecialistModal.forEach((button)=>{
    button.addEventListener('click',(event)=>{
      var modalParent = event.target.closest("li");
      modalParent.querySelector('.modal-item').style.display = 'block';
      
      scrollPosition = window.pageYOffset;
      $('html, body').css({'overflow-y': 'hidden'}); // 모달팝업 중 html,body의 scroll을 hidden시킴 
      body.style.top = `-${scrollPosition}px`;
    })
  });
  closeSpecialistModal.forEach((button)=>{
    button.addEventListener('click',(event)=>{
      var modal = button.parentNode;
      modal.style.display = 'none';
        
      $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
      window.scrollTo(0, scrollPosition);
    });
  });
  closeSpecialistModalBg.forEach((button)=>{
    button.addEventListener('click',(event)=>{
      var modal = button.parentNode.parentNode;
      modal.style.display = 'none';
        
      $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
      window.scrollTo(0, scrollPosition);
    });
  });
  closeSpecialistModalItem.forEach((button)=>{
    button.addEventListener('click',(event)=>{
      var modalParent = event.target.closest("li");
      modalParent.querySelector('.modal-item').style.display = 'none';
        
      $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
      window.scrollTo(0, scrollPosition);
    });
  });
  closeSpecialistLaserModalItem.forEach((button)=>{
    button.addEventListener('click',(event)=>{
      var modalParent = event.target.closest("li");
      modalParent.querySelector('.modal-item').style.display = 'none';
        
      $('html, body').css({'overflow-y': 'auto'}); //scroll hidden 해제 
      window.scrollTo(0, scrollPosition);
    });
  });
}



/* youtube click */
const ytBtns = Array.from(document.getElementsByClassName("yt-tab-thumb-item"));
const ytIframe = document.querySelector(".yt-tab-iframe iframe");
if(ytBtns){
	ytBtns.forEach((btn,index)=>{
		var src = btn.getAttribute('data-yt-src');
		
		btn.addEventListener('click',function(){
			var iframeSrc = 'https://www.youtube.com/embed/'+src;
			ytIframe.setAttribute('src',iframeSrc);
			// var tit = btn.querySelector('.yt-tit-txt').textContent;
			// var mainTit = document.querySelector('.yt-tit-target');
			// mainTit.textContent = tit;
			ytBtns.forEach(btn=>btn.classList.remove('active'));
			btn.classList.add('active');
		});
	})
}



$('.hotspot .icon').click(function () {
  var $parent = $(this).parent();
  $parent.toggleClass('open');
  $('.hotspot.open').not($parent).removeClass('open');
});

const chkallBtn = document.querySelector('input[name="ft_total_agree"]');
const chkitems = document.querySelectorAll('.privacy_cont input[type="checkbox"]');

if(chkallBtn){
  const pop = document.getElementsByClassName('hotspot')[0];
  const iconBox = document.getElementById('iconBox');
  chkallBtn.addEventListener('change',function(){
    chkitems.forEach((chkbox,index)=>{
      if(!chkbox.checked){
        chkbox.checked = true;
        iconBox.classList.add('checked');
      } else{
        chkbox.checked = false;
        iconBox.classList.remove('checked');
      }
      
    });
    pop.classList.remove('open');
    
  });
}


//2021-07-11 Namulsoft
$('.sub1-4-laser-morebtn').on('click', function(){
	$('.sub1-4-laser-list.more').toggle();
});


// 유투브 스와이프 코드
(function () {
    
  var ytSwiperSmall = new Swiper(".yt_thumb-container-small", {
    slidesPerView: "auto",
    spaceBetween: 5,
    loop: true,
    loopedSlides: 2,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    touchRatio: 0.2,
    loopAdditionalSlides: 1,
    grabCursor: true,
    observer: true,
    observeParents: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '.yt_thumb-small-ctrls.next',
      prevEl: '.yt_thumb-small-ctrls.prev',
    },
    breakpoints: {
      300: {
        slidesPerView: 3,
        spaceBetween: 1,
        direction: 'vertical',
      },
      376: {
        slidesPerView: 3,
        spaceBetween: 5,
        direction: 'vertical',
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 10,
        direction: 'vertical',
      },
      641: {
        slidesPerView: 2.5,
        spaceBetween: 5,
      },
      800: {
        slidesPerView: 2.5,
        spaceBetween: 5,
      },
      1024: {
        slidesPerView: 2.5,
        spaceBetween: 5,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 5,
      }
    },
  });

  var ytSwiper = new Swiper(".yt_thumb-container", {
    slidesPerView: 1,
    loop: true,
    loopedSlides: 2,
    loopAdditionalSlides: 1,
    centeredSlides : true,
    grabCursor: true,
    observer: true,
    observeParents: true,
    on: {
        slideChange: function (el) {
          /*console.log('1');*/
          $('.swiper-slide').each(function () {
            var youtubePlayer = $(this).find('iframe').get(0);
            if (youtubePlayer) {
                youtubePlayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            }
          });
        },
    },
  });
    
  //ytSwiper.slideTo(3, 10, false);
  //ytSwiperSmall.slideTo(3, 10, false);
    
  //ytSwiper.controller.control = ytSwiperSmall;
  //ytSwiperSmall.controller.control = ytSwiper;
  

  $(".yt_thumb-img-out1").click(function(){
    $(".yt_thumb-img-out1").fadeOut("slow");
  });
  $(".yt_thumb-img-out2").click(function(){
    $(".yt_thumb-img-out2").fadeOut("slow");
  });
  $(".yt_thumb-img-out3").click(function(){
    $(".yt_thumb-img-out3").fadeOut("slow");
  });
  $(".yt_thumb-img-out4").click(function(){
    $(".yt_thumb-img-out4").fadeOut("slow");
  });
  $(".yt_thumb-img-out5").click(function(){
    $(".yt_thumb-img-out5").fadeOut("slow");
  });
  $(".yt_thumb-img-out6").click(function(){
    $(".yt_thumb-img-out6").fadeOut("slow");
  });
  $(".yt_thumb-img-out7").click(function(){
    $(".yt_thumb-img-out7").fadeOut("slow");
  });
  $(".yt_thumb-img-out8").click(function(){
    $(".yt_thumb-img-out8").fadeOut("slow");
  });
  $(".yt_thumb-img-out9").click(function(){
    $(".yt_thumb-img-out9").fadeOut("slow");
  });
    
    
window.addEventListener('load', onLoadEvent);

var tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 생성된 id를 추가 시켜 영상을 조작하기 위한 객체
var player = {};

/*
    위의 첨부해둔 링크로 들어가보시면
    아래 함수 이름이 onYouTubeIframeAPIReady로 되어 있을텐데 이건 youtube api가 로드되면 바로 실행되기 때문에 swiper와 맞지 않다.
*/

function makeYoutube(id, link) {
  player[id] = new YT.Player(id, {
    height: '100%',
    width: '100%',
    playerVars: { autoplay: 0, controls: 1 },
    videoId: link,
    events: {
      onReady: onPlayerReady
    }
  });

  function onPlayerReady(event) {
    event.target.Video();
  }
}

/*
    조금이라도 첫 로딩을 빠르게 하기 위해
    .youtube를 클릭할 때 영상을 로드 시킴.
                                                     ↓        
    makeRandomId() 함수를 추가한 이유는 new YT.Player(id,{})
    이 부분에는 id만 들어가기 떄문이다.

    swiper.js option에 loop:true를 추가하게 되면 처음과 마지막 슬라이드의 복제가 생기는데 
    그렇게되면 id도 복제가 되서 같은 id가 2개가 된다.
    그럼 처음 검색된 id에만 동영상이 로딩되고 다음 id엔 로드가 되지 않는다.
    그래서 생각한 것이 html에는 id를 넣지 않고 클릭될 때 id를 추가해서 넣고 그 아이디로 영상을 로드시키는 방법이다.
*/

function yotubeOnClick() {
  [].forEach.call(document.querySelectorAll('.yt_thumb-video'), function(el, index) {
    el.addEventListener('click', function() {
      var thisYoutubeLink = this.getAttribute('data-youtubeLink');
      this.id = makeRandomId();
      makeYoutube(this.id, thisYoutubeLink);
    });
  });
}

// load될 때 클릭 이벤트와 swiper가 슬라이드 될 때 동영상을 stop 시키는 이벤트 추가.
function onLoadEvent() {
  yotubeOnClick();
  ytSwiper.on('slideChangeTransitionEnd', youtubeAllStop);
}

// 위에 생성해둔 player 객체 안에 있는 프로퍼티들을 검색해서 비디오 멈춤
function youtubeAllStop() {
  for (var youtube in player) {
    player[youtube].pauseVideo();
  }
}

var makeRandomId = function() {
  var text = '';
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+=-';

  for (var i = 0; i < 11; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};
 
  
})()